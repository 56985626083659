//import icons
import { general, pdf, video, audio, zip, word, ppt, excel, unspecified
} from '../../../assets/images/file-manager'

export const getFileIcon = (extension) => {
    switch ( extension ) {
        case 'pdf':
            return pdf;
        case 'wmv':
            return general;
        case 'mpeg':
        case 'avi':
        case 'flv':
        case 'mp4':
            return video;
        case 'mp3':
        case 'wav':
            return audio;
        case 'zip':
        case 'rar':
            return zip;
        case 'doc':
        case 'docx':
            return word;
        case 'ppt':
        case 'pptx':
            return ppt;
        case 'xls':
        case 'xlsx':
            return excel;
        default:
            return unspecified;
    }
};
