import {
    ACCEPT_FILE_TYPES,
    MAX_FILE_SIZE,
    ACCEPT_IMAGE_TYPES,
    ACCEPT_LOGO_ADD_TYPES
} from "../../../../constants/acceptedTypes";
import swal from "sweetalert";
import { FILE_TYPES } from "../../../../constants/fileTypes";
import { UPLOAD_TYPES } from "../../../../constants/constTypes";
import isURL from "validator/es/lib/isURL";
import { GetImageFileByUrl, setUpLoadProgresses } from "../../../../redux/actions";
import { store } from "../../../../redux/store";
import { uploadBox } from "../../UploadBox";
import logo from '../../../../assets/images/file-manager/logo.png'

export async function uploadMedias () {
    const { folderList, uploadProgresses } = this.props;
    const currentFolder = folderList?.[folderList?.length - 1];

    const { addingFiles, currentUploadingId } = this.state;
    let formData = new FormData();
    let errors = {}
    for ( let i = 0; i < addingFiles.length; i++ ) {
        const item = addingFiles[i];
        const uploadingFile = item.croppedImageFile || item.file
        //  console.log('uploadingFile', uploadingFile);
        // formData.append(i, uploadingFile, encodeURI(uploadingFile?.name));
        formData.append('files', uploadingFile);
        if (item.url) {
            if (isURL(item.url)) {
                const payload = {
                    url: item.url
                }
                formData.append('files', JSON.stringify(payload));
            } else {
                errors['url_' + i] = true
            }
        }
    }
    if (Object.keys(errors).length) {
        this.setState({
            errors
        })
        return
    }

    currentFolder && currentFolder.id && formData.append('parent', currentFolder.id);
    const uploadId = currentUploadingId || Date.now() // errori jamanak vor eli upload ani currentUploadingId nuyn@ piti mna,
    await this.setState({
        uploading: true,
        currentUploadingId: uploadId,
        errors, // delete old errors
    })
    await setUpLoadProgresses({
        ...uploadProgresses,
        [uploadId]: {
            progress: 0,
            itemsCount: addingFiles.length,
            status: 'active',
            folderId: currentFolder?.id,
            folderList,
            pathName: 'Ֆայլեր' + folderList?.map(f => '/' + f.name)
        }
    })

    this.props.UploadFile(formData, this.onUploadProgress, uploadId).then(async() => {
        const { uploadProgresses, folderList } = store.getState().media;
        const currentFolder = folderList?.[folderList?.length - 1];
        setUpLoadProgresses({
            ...uploadProgresses,
            [uploadId]: {
                ...uploadProgresses[uploadId],
                progress: 100,
                status: 'success',
            }
        })
        if (currentFolder?.id === uploadProgresses[uploadId].folderId) {
            this.props.GetMedia({ parent: currentFolder?.id });
        }
        if (this.state.currentUploadingId === uploadId) {
            this.closeAddMedia();
            const newUploadedProgresses = { ...uploadProgresses }
            delete newUploadedProgresses[uploadId];
            setUpLoadProgresses(newUploadedProgresses)
        }

    }).catch(() => {
        const { uploadProgresses, } = store.getState().media;
        setUpLoadProgresses({
            ...uploadProgresses,
            [uploadId]: {
                ...uploadProgresses[uploadId],
                status: 'exception',
            }
        })
        this.setState({
            uploading: false,
        })
    });
}


export async function onDropFile (e) {
    e.stopPropagation();
    e.preventDefault();
    const files = e?.target?.files || e?.dataTransfer?.files;
    // console.log('files',files)
    if (!files || !files.length) {
        return
    }
    let errorFiles = [];
    for ( let i = 0; i < files.length; i++ ) {
        let file = files[i];
        const extensionSupported = hasExtension(file.type);
        if (
            !extensionSupported
            //  || file.size > MAX_FILE_SIZE
        ) {

            errorFiles.push({
                name: file.name,
                extension: !extensionSupported,
                size: file.size > MAX_FILE_SIZE
            })
        }
    }
    if (errorFiles.length) {
        swal({
            title: "Զգուշացում!",
            text: errorFiles.map(errFile => {
                return `${errFile.name} - ${errFile.extension ? ' չթույլատրվաց ֆորմատով է, ' : ''} ${errFile.size ? ' չափսը 200mb -ից մեծ է,' : ''}`
            }).join('\n'),
            icon: "info",
            button: "Ok"
        });

    } else {
        const addingFiles = [];
        for ( let i = 0; i < files.length; i++ ) {
            let file = files[i];
            let objectUrl = URL.createObjectURL(file);

            console.log('file', file)
            addingFiles.push({
                imageUrl: objectUrl,
                file,
                originalFile: file,
                url: '',
                logoAdded: false,
            })
        }
        this.setState({
            addingFiles,
            filesIsChoose: true,
            addFileCardOpen: false,
            chooseType: null,
            name: '',
            url: '',
            errors: {},
        })
    }
}


export async function addLogoToImage (index = null) {
    if (index === null) {
        const promises = this.state.addingFiles.map(item => {
            if (ACCEPT_LOGO_ADD_TYPES.some(acceptType => acceptType === FILE_TYPES[item?.file?.type]) && !item.logoAdded) {
                return addLogo(item)
            } else {
                return item
            }
        })
        await Promise.all(promises).then(filesList => {
            this.setState({
                addingFiles: filesList
            })
        })
    } else {
        const image = await addLogo(this.state.addingFiles[index])
        this.setState({
            addingFiles: this.state.addingFiles.map((item, pos) => pos === index ? image : item)
        })
    }
}

export async function addLogo (image) {
    console.log('image', image)

    try {
        if (image.logoAdded) {
            image.logoAdded = false
            image.file = image.originalFile
            image.imageUrl = URL.createObjectURL(image.originalFile);
        } else {
            console.log('in try')
            let file = image.file;
            let objectUrl = image.imageUrl;
            let width, height;
            const img = new Image();
            await new Promise(resolve => {
                //get image dimensions
                img.onload = function() {
                    console.log(this.width + " " + this.height);
                    width = this.width;
                    height = this.height;
                    resolve()
                };
                img.src = objectUrl;
            })
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            const context = canvas.getContext('2d');
            const uploadImage = new Image();

            await new Promise(resolve => {
                uploadImage.src = objectUrl
                uploadImage.onload = function() {
                    context.drawImage(uploadImage, 0, 0, width, height);
                    resolve()
                };
            })
            const logoImage = new Image();
            await new Promise(resolve => {
                logoImage.src = logo
                logoImage.onload = function() {

                    const logoWidth = width * 0.4
                    const logoProportion = 1
                    const logoHeight = logoWidth * logoProportion
                    const margin = logoWidth * 0.1
                    //height - logoHeight - margin
                    context.drawImage(logoImage, width / 2, height - margin - logoHeight, logoWidth, logoHeight);
                    resolve()
                };
            })

            // const pngBase64Url = canvas.toDataURL();
            // const jpegBase64Url = canvas.toDataURL('image/jpeg');

            const type = FILE_TYPES[file.type];
            const imageBlob = await new Promise(resolve => {
                canvas.toBlob(blob => {
                    resolve(blob)
                }, `image/${type}`)
            })
            console.log('file', file)
            console.log('type', type)
            image.logoAdded = true
            image.file = new File([imageBlob], file.name, { type: `image/${type}` });
            image.imageUrl = URL.createObjectURL(imageBlob);
        }
    } catch ( e ) {
        console.log('error add logo at image ',)
    }
    return image
}


export function minimizeUpload () {
    const { currentUploadingId } = this.state;
    uploadBox(currentUploadingId)
    this.closeAddMedia();
}

export function onUploadProgress (progressEvent, uploadId) {
    const uploadProgresses = store.getState().media.uploadProgresses;
    // console.log('onUploadProgress', uploadProgresses)
    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

    // console.log('percentCompleted', percentCompleted)
    setUpLoadProgresses({
        ...uploadProgresses,
        [uploadId]: {
            ...uploadProgresses[uploadId],
            progress: percentCompleted !== 100 ? percentCompleted : 99
        }
    })
}

export function hasExtension (fileType) {
    const type = FILE_TYPES[fileType];
    if (!type) {
        return false;
    }
    return ACCEPT_FILE_TYPES.some(acceptType => acceptType === type);
}

export function isImage (fileType) {
    const type = FILE_TYPES[fileType];
    if (!type) {
        return false;
    }
    return ACCEPT_IMAGE_TYPES.some(acceptType => acceptType === type);
}

export function getMedias (addingFiles) {
    this.setState({
        addingFiles
    });
    !addingFiles.length && this.closeAddMedia();
}

export function onUploadClick (e) {
    e.target.value = null;
}

export function triggerFileUpload () {
    this.inputElement.current.click();
}

export function closeAddMedia () {
    this.setState({
        uploading: false,
        filesIsChoose: false,
        currentUploadingId: null,
        addingFiles: [],
        errors: {}
    })
}


// Media add by url

export function toggleAddFileCard (visible) {
    this.setState({
        addFileCardOpen: visible
    })
}

export function cancelAddFileByUrl () {
    this.setState({
        chooseType: null,
        errors: {},
        name: '',
        url: '',
        fileByUrl: null,
        addFileCardOpen: true,
    })
}

export function chooseUploadType () {
    this.setState({
        chooseType: UPLOAD_TYPES.URL
    })
}

export function getFileUploadByUrlValues ({ name, value }) {
    const newState = {
        [name]: value,
    }
    if (name === 'url' && value) {
        const path = value.split('/')?.slice(-1)?.[0];
        if (path && path.includes('.')) {
            const _name = path.split('.')[0];

            if (_name.endsWith('200x200')) {
                //Yandex
                newState.url = value.replace('200x200', '400x400');
            }
            if (_name.endsWith('_s400')) {
                //Shazam
                newState.url = value.replace('_s400', '_s800');
            }
            if (_name.endsWith('400x400bb')) {
                //Shazam
                newState.url = value.replace('400x400bb', '800x800bb');
            }
            if (_name.endsWith('500x500bb-60')) {
                //Apple
                newState.url = value.replace('500x500bb-60', '800x800bb-60');
            }

            // if (!this.state.name) {
            //     _name && (newState.name = _name);
            // }
        }
    }
    this.setState(newState);
}

export async function addFile () {
    const { url, name } = this.state;

    // console.log("name", url, name)
    if (!url || !isURL(url)) {
        this.setState({
            errors: {
                url: true
            },
        });
        return;
    } else {
        this.setState({
            errors: {}
        })
    }
    await this.setState({
        loadingGetFileByUrl: true
    })
    await GetImageFileByUrl(url, name || 'file').then(file => {
        this.state.addFileCardOpen && this.onDropFile({
            target: {
                files: [file]
            }
        })
    }).finally(() => {
        this.setState({
            loadingGetFileByUrl: false,
        })
    });

}

function dataURLtoBlob (dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while ( n-- ) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}
