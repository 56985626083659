//file upload
export const MAX_FILE_SIZE = 209715200; //200mb
export const ACCEPT_FILE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'pdf', 'wmv', 'mpeg',
    'avi', 'flv', 'mp3', 'mp4', 'wav', 'zip', 'rar', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'webp'];
export const ACCEPT_IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
export const ACCEPT_VIDEO_TYPES = ['mp4'];
export const ACCEPT_LOGO_ADD_TYPES = ['jpg', 'jpeg', 'png', 'webp'];
export const ACCEPT_IMAGE_SIZE = 10485760; // 10mb

export const LOCKED_MEDIA_FOLDERS = [];
export const ACCEPT_AUDIO_TYPES = ['3gp', 'midi', 'mp3', 'ogg', 'wav', 'wma', 'mpeg'];
