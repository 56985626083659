import React from "react";
import { store } from "../../redux/store";

import { notification, Progress } from "antd";

import { setFolderList, setUpLoadProgresses } from "../../redux/actions";
import { history } from "../../configs/history";

import '../../assets/styles/media/upload-box.scss'
import {IconUpload} from "../../assets/images";

export function uploadBox(uploadId){
    const { uploadProgresses } = store.getState().media;
    if ( uploadId ) {
        _showUploadBox(uploadId);
        const unSubscriber = store.subscribe(_showUploadBox.bind(null, uploadId));
        setUpLoadProgresses({
            ...uploadProgresses,
            [uploadId]: {
                ...uploadProgresses[uploadId],
                unSubscriber
            }
        })
    }
}

function _showUploadBox(uploadId){
    const { uploadProgresses } = store.getState().media;
    const { progress = 0, status, unSubscriber, pathName, itemsCount, folderList } = uploadProgresses[uploadId] || {};
    notification.open({
        key: uploadId,
        className: 'upload-box',
        style: {
            width: 300,
        },
        placement: 'bottomLeft',
        message: <span>
            <IconUpload title={''} className={'upload-icon'}/>
            {`${itemsCount} ֆայլի բեռնում`}
        </span>,
        description: <div className={`progress ${status !== 'success' ? 'show' : ''}`}>
            <div className={'file-route'} onClick={() => {
                setFolderList(folderList);
                if(!history.location.pathname.startsWith('/media') ){
                   history.push('/media')
                }
            }}>
                {pathName}</div>
            <Progress percent={progress} status={status}/>
        </div>,
        duration: 0,
        onClose: _closeNotification.bind(null, uploadId)

    });
    if ( progress === 100 ) {
        unSubscriber()
    }
}

function _closeNotification(uploadId){
    const { uploadProgresses } = store.getState().media;
    const { progress = 0, unSubscriber } = uploadProgresses[uploadId] || {};

    progress !== 100 && unSubscriber && unSubscriber();
    const newUploadedProgresses = { ...uploadProgresses };
    delete newUploadedProgresses[uploadId];
    setUpLoadProgresses(newUploadedProgresses)
}
