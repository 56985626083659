//media modal
export const MEDIA_MODAL_TYPES = {
    MEDIA_MAIN: 'MEDIA_MAIN',
    MEDIA_ARRAY: 'MEDIA_ARRAY',
};

export const MEDIA_TYPES = {
    FILE: 'FILE',
    FOLDER: 'FOLDER',
};

export const UPLOAD_TYPES = {
    SELF: 'SELF',
    URL: 'URL',
};


export const SLIDER_RESOURCE_TYPES = {
    BLOG: {
        title: 'Բլոգ',
        key: 'BLOG',
    },
    /*CUSTOM_PAGE:{
        title: 'Դինամիկ Էջ',
        key: 'CUSTOM_PAGE',
    },*/
};

export const BLOG_TYPES = {
    EVENT: {
        key: "EVENT",
        title: "EVENT"
    },
    PROJECT: {
        key: "PROJECT",
        title: "PROJECT"
    },
    TRAVELING: {
        key: "TRAVELING",
        title: "TRAVELING"
    },
    VIDEO: {
        key: "VIDEO",
        title: "VIDEO"
    },
}
