export const ModeratorAddingList = [
    {
        label: "Անուն",
        inputType: "input",
        type: "text",
        name: "firstName",
        maxLength: 50,
        required: true,
    },
    {
        label: "Ազգանուն",
        inputType: "input",
        type: "text",
        name: "lastName",
        maxLength: 50,
        required: true,
    },
    {
        label: "Էլ․ հասցե",
        inputType: "input",
        type: "text",
        name: "email",
        maxLength: 50,
        required: true,
    },
];
