// Import packages
import React from "react";
import {Route, Switch, Router, Redirect} from "react-router-dom";

// Import utils
import {history} from "../configs/history";
import {ProtectedRoute} from "./protectedRoute";

// Import pages
import AdminLayout from '../containers/AdminLayout';
import Login from '../containers/Login';

import NotFound from '../containers/NotFound';
import Contact from '../containers/Contact';


import Media from "../containers/media/Media";
import EditFile from "../containers/media/EditFile";

import Languages from "../containers/languages/Languages";
import EditLanguage from "../containers/languages/EditLanguage";

import Partner from "../containers/partners/Partner";
import AddEditPartner from "../containers/partners/AddEditPartner";

import StaticTexts from "../containers/staticTexts/StaticTexts";

import Subscribers from "../containers/subscribers/Subscribers";
import Requests from "../containers/call-requests/CallRequests";

import OrderRequests from "../containers/order-requests/OrderRequests";
import OrderRequestDetails from "../containers/order-requests/OrderDetails";

import About from "../containers/about/About";
import AddEditAboutBlock from "../containers/about/AddEditAboutBlock";

import Service from "../containers/services/Service";
import AddEditServiceBlock from "../containers/services/AddEditServiceBlock";

import Product from "../containers/product/Product";
import AddEditProduct from "../containers/product/AddEditProduct";

// import Slider from "../containers/slider/Slider";
// import AddEditSlider from "../containers/slider/AddEditSlider";

import News from "../containers/news/News";
import AddEditNews from "../containers/news/AddEditNews";

import Home from "../containers/home/Home";
import AddEditModerator from "../containers/Moderators/AddEditModerator";
import Moderators from "../containers/Moderators/Moderators";
import AddEditBlock from "../containers/home/AddEditBlock";
import AddEditOption from "../containers/home/AddEditOption";
import Info from "../containers/info/Info";


export default () => {

    return <Router history={history}>
        <Switch>
            <Redirect exact from="/" to="/login"/>
            <Route exact path="/login" component={Login}/>
            <AdminLayout>
                <Switch>
                    <Route exact path="/dashboard" component={Home}/>

                    <Route exact path="/:name/block/edit/:id" component={AddEditBlock}/>
                    <Route exact path="/:name/block/:blockId/option/add" component={AddEditOption}/>
                    <Route exact path="/:name/block/:blockId/option/edit/:id" component={AddEditOption}/>

                    <ProtectedRoute exact path="/moderators" component={Moderators}/>
                    <ProtectedRoute exact path="/moderators/add" component={AddEditModerator}/>
                    <ProtectedRoute exact path="/moderators/edit/:id" component={AddEditModerator}/>

                    {/*<ProtectedRoute exact path="/slider" component={Slider}/>*/}
                    {/*<ProtectedRoute exact path="/slider/add" component={AddEditSlider}/>*/}
                    {/*<ProtectedRoute exact path="/slider/edit/:id" component={AddEditSlider}/>*/}

                    <ProtectedRoute exact path="/media" component={Media}/>
                    <ProtectedRoute exact path="/media/edit/:id" component={EditFile}/>

                    <ProtectedRoute exact path="/news" component={News}/>
                    <ProtectedRoute exact path="/news/add" component={AddEditNews}/>
                    <ProtectedRoute exact path="/news/edit/:id" component={AddEditNews}/>

                    <ProtectedRoute exact path="/partners" component={Partner}/>
                    <ProtectedRoute exact path="/partners/add" component={AddEditPartner}/>
                    <ProtectedRoute exact path="/partners/edit/:id" component={AddEditPartner}/>

                    <ProtectedRoute exact path="/products" component={Product}/>
                    <ProtectedRoute exact path="/products/add" component={AddEditProduct}/>
                    <ProtectedRoute exact path="/products/edit/:id" component={AddEditProduct}/>

                    <ProtectedRoute exact path="/subscribers" component={Subscribers}/>
                    <ProtectedRoute exact path="/requests" component={Requests}/>
                    <ProtectedRoute exact path="/order-requests" component={OrderRequests}/>
                    <ProtectedRoute exact path="/order-requests/:id" component={OrderRequestDetails}/>

                    <ProtectedRoute exact path="/about" component={About}/>
                    {/*<ProtectedRoute exact path="/about/block/add" component={AddEditAboutBlock}/>*/}
                    {/*<ProtectedRoute exact path="/about/block/edit/:id" component={AddEditAboutBlock}/>*/}

                    <ProtectedRoute exact path="/services" component={Service}/>

                    <ProtectedRoute exact path="/info" component={Info}/>
                    {/*<ProtectedRoute exact path="/services/block/add" component={AddEditServiceBlock}/>*/}
                    {/*<ProtectedRoute exact path="/services/block/edit/:id" component={AddEditServiceBlock}/>*/}

                    <ProtectedRoute exact path="/languages" component={Languages}/>
                    <ProtectedRoute exact path="/languages/edit/:id" component={EditLanguage}/>

                    <ProtectedRoute exact path="/contact" component={Contact}/>
                    <ProtectedRoute exact path="/staticTexts" component={StaticTexts}/>

                    <Route exact path="/404" component={NotFound}/>
                    <Route component={NotFound}/>
                </Switch>
            </AdminLayout>
        </Switch>
    </Router>
}
