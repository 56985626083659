// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../assets/styles/containerStyles/contact.scss";

// Import components
import {InputGroup} from "../components/uiElements/inputGroup";
import PageWrapper from '../components/pageContentViews/pageWrapper'
import {LinkButton} from "../components/buttons/buttons";
import {LanguageTabs} from "../components/uiElements/Tabs";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {GetContact, UpdateContact} from "../redux/actions";
import {
    changeStateField,
    getInputValues,
    initTranslations,
    getTranslatableInputValues, getTranslationData, toggleStateField
} from "../utils/helperFunctions";
import {validateTranslations} from "../utils/validateTranslations";
import {isEmail} from "validator";
import MediaSelectorModal from "../components/media/MediaSelectorModal";


export class Contact extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'address',
            'presentationFile',
        ]
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                email: "",
                phoneNumber: "",
            },
            isEditing: true,
            errorsTabs: [],
            errors: {},
            languageTab: props.mainLanguage,
            mediaModalOpen: false,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.togglePresentationFileModal = toggleStateField.bind(this, 'presentationFileModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.getPresentationFile = this.getPresentationFile.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.updateContact = this.updateContact.bind(this);
    }


    async componentDidMount() {
        !this.props?.contactData?.id && await this.props.GetContact();
        const {contactData} = this.props;
        const {fieldsData} = this.state;

        const translationsData = {};
        Object.keys(fieldsData.translations).forEach(key => {
            const trItem = contactData?.translations?.find(item => item.language === key);
            translationsData[key] = {
                ...fieldsData.translations[key],
                address: trItem?.address || '',

            };
        });
        contactData && this.setState({
            fieldsData: {
                email: contactData.email || "",
                translations: translationsData,
                phoneNumber: contactData?.phoneNumber,
            },
        });
    }

    getPresentationFile(pdf) {
        const {fieldsData,languageTab} = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: {
                    ...fieldsData.translations,
                    [languageTab]: {
                        ...fieldsData.translations[languageTab],
                        presentationFile: pdf[0],
                    }
                }
            },
        })
    }

    validateFields(constacData) {
        const translationRequiredFields = ['address'];
        const validationTr = validateTranslations(constacData.translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        if (!constacData.email || !isEmail(constacData.email)) {
            result = false;
            errors.email = true;
        }
        if (!result) {
            this.setState({
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
        return result;
    }

    updateContact() {
        const {fieldsData} = this.state;
        const {translations, phoneNumber} = fieldsData;
        if (this.validateFields(fieldsData)) {
            let TR_data = getTranslationData(translations);
            let reqData = {
                email: fieldsData.email,
                translations: TR_data,
                phoneNumber
            };
            this.props.UpdateContact(reqData).then(() => {
                this.updatedDataMap.clear();
                this.setState({
                    errors: {},
                    errorsTabs: []
                })
            })
        }
    }

    render() {
        const {requestLoading, contactData: contact} = this.props;
        const {fieldsData, languageTab, errors, errorsTabs,presentationFileModalOpen} = this.state;

        const {translations} = fieldsData;
        const initTranslations = contact?.translations?.find(lg => lg.language === languageTab) || {};

        const trData = translations[languageTab] || {};
        return <PageWrapper pageTitle={'Կապ'}>
            <section className="contact">
                <div className="info-adding-fields">
                    <InputGroup
                        label={"Էլ․ հասցե"}
                        inputType={'input'}
                        type={'text'}
                        placeholder={"Էլ․ հասցե"}
                        name={'email'}
                        maxLength={50}
                        error={errors.email}
                        required={true}
                        value={fieldsData?.email}
                        initValue={contact?.email}
                        onChange={this.getInputValues}/>
                    <InputGroup
                        label={"հեռախոսահամար"}
                        inputType={'input'}
                        type={'text'}
                        placeholder={"հեռախոսահամար"}
                        name={'phoneNumber'}
                        maxLength={20}
                        error={errors.phoneNumber}
                        required={true}
                        value={fieldsData?.phoneNumber}
                        initValue={contact?.phoneNumber}
                        onChange={this.getInputValues}/>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>

                <div className={'label-and-action'}>
                    <label>Կցել Presentation File</label>
                    <LinkButton className={"media-select-btn bg-white"}
                                title={"Ընտրել PDF ֆայլ"}
                                cb={this.togglePresentationFileModal}/>
                </div>
                <div className={'contract-wrapper'}>
                    <div className={'wrapper'}>
                        <span>Անվանում՝ {fieldsData.translations[languageTab]?.presentationFile?.name || initTranslations?.presentationFile?.name}</span>
                    </div>
                </div>

                <InputGroup inputType="input"
                            type="text"
                            label={'Հասցե'}
                            name="address"
                            maxLength={100}
                            value={trData?.address}
                            required={true}
                            initValue={initTranslations?.address}
                            error={errors['address' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>

                <div className="flex-wrapper-right">
                    <LinkButton title="Պահպանել"
                                loading={requestLoading}
                                cb={this.updateContact}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!presentationFileModalOpen}
                acceptTypes={['pdf']}
                getMedia={this.getPresentationFile}
                appendMedias={fieldsData.translations[languageTab]?.presentationFile}
                closeModal={this.togglePresentationFileModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetContact,
    UpdateContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
