// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";

// Import utils
import {
    changeStateField, deleteItem,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations, toggleStateField,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    GetAboutData,
    UpdateAboutData
} from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { MediaFileCard } from "../../components/media/MediaFileCard";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import PageHeader from "../../components/pageContentViews/pageHeader";
import { NestableItem } from "../../components/cards/nestableItem";
import Nestable from "react-nestable";

class About extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                blocks: [],
            },
            errors: {},
            errorsTabs: [],
            languageTab: props.mainLanguage,
            requestLoading: false,
            isEditing: true,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
        this.updateAbout = this.updateAbout.bind(this);
        this.deleteBlock = this.deleteBlock.bind(this);

    }

    async componentDidMount () {
        await this.props.GetAboutData();
        const { aboutData } = this.props;
        if (aboutData) {
            const { fieldsData } = this.state;
            const translationsData = {};

            if (aboutData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = aboutData?.translations.find(item => item.language === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
                errors: {},
                errorsTabs: [],
                requestLoading: false,
            });

        }
    }


    async updateAbout () {
        await this.setState({
            requestLoading: true
        });
        const { translations, mediaMain, } = this.state.fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (result) {
            const reqData = {
                translations: getTranslationData(translations)
            };
            this.props.UpdateAboutData(reqData).then(() => {
                this.updatedDataMap.clear();
                this.currentData = JSON.parse(JSON.stringify({
                    translations
                }));
            }).catch(() => {
            }).finally(() => {
                this.setState({ requestLoading: false });
            });
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }

    }

    getNestableItems (list) {
        const items = [];
        list && !!list.length && list.map((item, index) => {
            items.push({
                id: index,
                title: item?.title,
                withImage: true,
                path: item?.mediaMain?.path,
                link: {
                    pathname: `/about/block/edit/${item?.id}`,
                },
                data: item,
                children: []
            })
        });
        return items;
    }

    deleteBlock (pos) {
        const { aboutData } = this.props;
        const reqData = {
            blocks: aboutData.blocks.map(block => {
                return {
                    ...block,
                    mediaMain: block?.mediaMain?.id || null,
                }
            }).filter((_,index) => +pos !== index)
        };
        this.props.UpdateAboutData(reqData)
    }

    onPositionChange (items) {
        const blocks = items.map(item => item.data);

        const reqData = {
            blocks: blocks.map(block => {
                return {
                    ...block,
                    mediaMain: block?.mediaMain?.id || null,
                }
            })
        }
        this.props.UpdateAboutData(reqData)
    }

    render () {
        const {
            fieldsData,
            errors,
            languageTab,
            requestLoading,
            mediaModalOpen,
            errorsTabs
        } = this.state;
        const { aboutData } = this.props;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        let items = this.getNestableItems(aboutData?.blocks);
        return <PageWrapper pageTitle={'Մեր Մասին'}>

            <section className="about">
                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Վերնագիր"
                                placeholder="Վերնագիր"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="նկարագրությունը"
                                placeholder="նկարագրությունը"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>

                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                disabled={!this.updatedDataMap.size}
                                cb={this.updateAbout}/>
                </div>
                <div>
                    <label className={'list-label'}>Բաժիններ</label>
                    <PageHeader linkTitle={"Ավելացնել"}
                                addingLink={`/about/block/add`}/>

                    <Nestable
                        items={items}
                        maxDepth={1}
                        onChange={this.onPositionChange}
                        renderItem={NestableItem}
                    />

                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetAboutData,
    UpdateAboutData,
};

export default connect(mapStateToProps, mapDispatchToProps)(About)
