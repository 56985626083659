// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues, toggleStateField,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import {CreatePartner, GetPartnerById, UpdatePartnersById} from "../../redux/actions";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {Switch} from "antd";


class AddEditPartner extends Component {
    constructor(props){
        super(props);

        this.state = {
            fieldsData: {
                mediaMain: undefined,
                link: '',
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditPartners = this.addEditPartners.bind(this);
    }

    async componentDidMount () {
        const { isEditing, } = this.state;
        const { id } = this.props;

        if (isEditing) {
            await this.props.GetPartnerById(id)
            const { partnerById } = this.props;
            const initStateData = {
                link: partnerById?.link,
                mediaMain: partnerById?.mediaMain,
                isHidden: partnerById?.isHidden,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia(mediaArray){
        const { fieldsData, errors, isEditing } = this.state;
        if ( isEditing ) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    deleteMedia(){
        const { fieldsData, isEditing } = this.state;
        if ( isEditing ) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    addEditPartners(){
        this.setState({
            requestLoading: true
        });
        const { link,mediaMain, isHidden } = this.state.fieldsData;
         let result = true;
        const errors = {};

        if ( !mediaMain ) {
            errors.mediaMain = true;
            result = false;
        }

        if ( result ) {
                const reqData = {
                    mediaMain: mediaMain.id,
                    isHidden,
                };
                if ( link ) reqData.link = link;
            if ( !this.state.isEditing ) {
                this.props.CreatePartner(reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    })
            } else {
                this.props.UpdatePartnersById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            }

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
            })
        }
    }

    render(){
        const { fieldsData, errors, requestLoading,mediaModalOpen, isEditing } = this.state;

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Մենյու' : 'Փոփոխել Մենյուն'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <InputGroup inputType="input"
                            type="text"
                            label="Հղում"
                            placeholder="Հղում"
                            name="link"
                            value={fieldsData.link}
                            initValue={this.currentData?.link}
                            error={errors['link']}
                            maxLength={500}
                            required={false}
                            onChange={this.getInputValues}/>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditPartners}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreatePartner,
    GetPartnerById,
    UpdatePartnersById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPartner)
