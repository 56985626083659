import {request, _urlInfo} from "../api";
import {INFO_CONSTS} from "../constants";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetInfoData = () => {
    const requestData = {
        url: _urlInfo,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: INFO_CONSTS.SET_INFO_DATA,
                    payload: data
                });
            })
    }
};

export const UpdateInfoData = (data) => {
    const requestData = {
        url: `${_urlInfo}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: INFO_CONSTS.SET_INFO_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տեղեկատվությունը հաջողությամբ փոփոխվեց!"
                });
            })
    }
};
