// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField, deleteItem,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetBlockById, UpdateBlock} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_FILE_TYPES, ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {history} from "../../configs/history";
import {Switch} from "antd";
import PageHeader from "../../components/pageContentViews/pageHeader";
import Nestable from "react-nestable";
import {NestableItem} from "../../components/cards/nestableItem";


class AddEditBlock extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description'
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                pdfs: undefined,
                isHidden: false,
                link: '',
                blocks: [],
            },
            errors: {},
            errorsTabs: [],
            isEditing: true,
            languageTab: props.mainLanguage,
            requestLoading: false,
            mediaModalType: '',
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditBlock = this.addEditBlock.bind(this);
    }

    async componentDidMount() {
        const id = this.props?.match?.params?.id
        const {isEditing, fieldsData} = this.state;
        let editingData = null;
        this.props.GetBlockById(id)
            .then((res) => {
                editingData = res
                const translationsData = {};
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData?.translations?.find(item => item.language === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                    };
                });
                const initStateData = {
                    translations: translationsData,
                    mediaMain: editingData?.mediaMain,
                    pdfs: editingData?.pdfs?.[0],
                    isHidden: editingData?.isHidden,
                    link: editingData?.link || '',
                    options: editingData?.options,
                };
                this.currentData = JSON.parse(JSON.stringify(initStateData));
                this.setState({
                    fieldsData: initStateData,
                });
            })
        if (isEditing && !id) {
            history.push('/dashboard');
            return;
        }

        if (isEditing) {

        }
    }

    getMedia(type, mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set(type, mediaArray[0]?.id)
                : this.updatedDataMap.delete(type);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [type]: mediaArray[0],
            },
            errors: {
                ...errors,
                [type]: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing, mediaModalType} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set(mediaModalType, null) :
                this.updatedDataMap.delete(mediaModalType);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [mediaModalType]: null
            }
        })
    }

    getNestableItems(list) {
        const {mainLanguage} = this.props;
        const items = [];
        const id = this.props?.match?.params?.id
        list && !!list.length && list.forEach((item, index) => {
            const trData = item?.translations?.find(tr => tr.language === mainLanguage);
            const title = trData?.title || '';
            const desc = trData?.description || '';

            items.push({
                id: item?.id,
                title: title || desc,
                withImage: true,
                path: item?.mediaMain?.path,
                link: {
                    pathname: `/${this.props?.match?.params?.name}/block/${id}/option/edit/${index}`,
                },
                data: item,
                deleteCb: deleteItem.bind(this, this.deleteServices, 'Տարբերակը', item?.slug),
                children: []
            })
        });
        return items;
    }

    deleteServices(slug) {
        // const { servicesData } = this.props;
        // const reqData = {
        //     blocks: servicesData.blocks.map(block => {
        //         return {
        //             ...block,
        //             mediaMain: block?.mediaMain?.id || null,
        //             icon: block?.icon?.id || null,
        //         }
        //     }).filter(b => b.slug !== slug)
        // };
        // this.props.UpdateServicesData(reqData)
    }

    onPositionChange(items) {
        // const blocks = items.map(item => item.data);

        // const reqData = {
        //     blocks: blocks.map(block => {
        //         return {
        //             ...block,
        //             mediaMain: block?.mediaMain?.id || null,
        //             icon: block?.icon?.id || null,
        //         }
        //     })
        // }
        // this.props.UpdateServicesData(reqData)
    }

    toggleMediaModalType(type = '') {
        this.setState({
            mediaModalType: type
        })
    }

    async addEditBlock() {
        await this.setState({
            requestLoading: true
        });
        const id = this.props?.match?.params?.id

        const {translations, mediaMain, pdfs, isHidden, link} = this.state.fieldsData;
        const translationRequiredFields = [];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (result) {
            const trData = getTranslationData(translations);
            let reqData = {
                translations: trData,
                isHidden,
            }
            mediaMain?.id && (reqData.mediaMain = mediaMain?.id)
            pdfs?.id && (reqData.pdfs = [pdfs?.id])
            if (link) {
                reqData.link = link
            }
            /*const reqData = {
                blocks: aboutData?.blocks.map((block, index) => {
                    if (index === +id) {
                        return newBlock
                    }
                    return {
                        ...block,
                        mediaMain: block?.mediaMain?.id || null,
                    }
                })
            }*/
            this.props.UpdateBlock(id, reqData).finally(() => {
                this.setState({requestLoading: false});
                history.push(`/${this.props?.match?.params?.name}`)
            })
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {fieldsData, errors, languageTab, mediaModalType, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        let items = this.getNestableItems(fieldsData?.options);
        const id = this.props?.match?.params?.id

        return <PageWrapper pageTitle={'Փոփոխել Բլոկը'}>
            <section className="slider-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label>Նկար<span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModalType.bind(this, 'mediaMain')}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <div className="top-side">
                    <label>PDF<span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.pdfs ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModalType.bind(this, 'pdfs')}
                    />
                </div>
                {fieldsData?.pdfs && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.pdfs}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <InputGroup inputType="input"
                            type="text"
                            label="Հղում"
                            placeholder="Հղում"
                            name="link"
                            value={fieldsData?.link}
                            initValue={this.currentData?.link}
                            error={errors?.link}
                            maxLength={100}
                            onChange={this.getInputValues}/>
                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անուն"
                                placeholder="Անուն"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Քարտի նկարագրությունը"
                                placeholder="Քարտի նկարագրությունը"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditBlock}/>
                </div>
                <div>
                    <label className={'list-label'}>Տարբերակ</label>
                    <PageHeader linkTitle={"Ավելացնել"}
                                addingLink={`/${this.props?.match?.params?.name}/block/${id}/option/add`}/>

                    <Nestable
                        items={items}
                        maxDepth={1}
                        onChange={this.onPositionChange}
                        renderItem={NestableItem}
                    />

                </div>
            </section>

            <MediaSelectorModal
                isOpen={!!mediaModalType}
                acceptTypes={mediaModalType === 'pdfs' ? ACCEPT_FILE_TYPES : ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia.bind(this, mediaModalType)}
                closeModal={this.toggleMediaModalType.bind(this)}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    UpdateBlock,
    GetBlockById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBlock)
