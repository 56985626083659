import {INFO_CONSTS} from "../constants";

export const initialState = {
    infoData: null,
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case INFO_CONSTS.SET_INFO_DATA:
            return {
                ...state,
                infoData: action.payload ,
            };
        default:
            return state;
    }
}
