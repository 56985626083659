// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { history } from "../../configs/history";
import { Tag } from "antd";
import swal from "sweetalert";

// Import styles
import "../../assets/styles/containerStyles/media.scss";
import "../../assets/styles/media/media-header.scss";
import {
    IconDeleteDark, IconSearchDark, IconCloseDark, IconFolder, IconClock,
    iconGrid, iconList, iconFile
} from "../../assets/images";

// Import components
import { MediaFileCard } from "../../components/media/MediaFileCard";
import { MediaFolderCard } from "../../components/media/MediaFolderCard";
import { InputGroup } from "../../components/uiElements/inputGroup";
import PreviewModal from '../../components/media/PreviewModal';
import MediaUploaderWrapper from "../../components/media/AddMedia/MediaUploaderWrapper";
import AddMediaFolder from "../../components/media/AddFolder";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import { LinkButton } from "../../components/buttons/buttons";
import InfiniteScroll from "react-infinite-scroller";
import { LoadingOutlined } from "@ant-design/icons";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    AddFolder,
    DeleteFile,
    DeleteFolder,
    GetMedia,
    setFolderList,
    toggleMediaView,
    UpdateFile,
    updateMediaPath,
    getMediaHistory,
    deleteMediaHistory
} from "../../redux/actions";

class Media extends Component {
    constructor (props) {
        super(props);

        this.state = {
            searchValue: '',
            searchType: null,
            draggableFile: null,
            previewModalOpen: false,
            previewItem: null,
            searchClosed: true,
            fetching: false,
            selectedItems: []
        };

        this.getSearchValue = this.getSearchValue.bind(this);
        this.search = this.search.bind(this);
        this.openFolder = this.openFolder.bind(this);
        this.deleteFolder = this.deleteFolder.bind(this);
        this.onDropToFolder = this.onDropToFolder.bind(this);
        this.dragFile = this.dragFile.bind(this);
        this.openPreview = this.openPreview.bind(this,);
        this.closePreview = this.closePreview.bind(this,);
        this.toggleSearch = this.toggleSearch.bind(this,);
        this.loadMoreItems = this.loadMoreItems.bind(this,);
        this.getFiles = this.getFiles.bind(this,);
        this.selectCard = this.selectCard.bind(this,);
        this.removeSelect = this.removeSelect.bind(this,);
        this.deleteMediaList = this.deleteMediaList.bind(this,);
        this.toggleSearchItem = this.toggleSearchItem.bind(this);
        this.toggleSearchType = this.toggleSearchType.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
    }

    componentDidMount () {
        const { location, folderList } = this.props;
        console.log('mount')
        if (folderList?.length) {
            const currentFolder = folderList[folderList.length - 1];
            history.push({
                pathname: '/media',
                search: `?currentFolder=${currentFolder?.name}`
            })
            this.getFiles(currentFolder.id);
        } else {
            if (location.search) {
                history.push({
                    pathname: '/media',
                })
            }
            this.getFiles();
        }

        // window.addEventListener('click', (e) => {
        //     this.removeSelect(e);
        //     !this.state.searchClosed && this.toggleSearch(e);
        // });
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        const { location, folderList } = this.props;
        if (!location?.search && prevProps?.location?.search && folderList?.length) {
            setFolderList([])
        }
        console.log('folder changed', folderList !== prevProps?.folderList)
        if (folderList !== prevProps?.folderList) {
            // console.log('changed')
            const currentFolder = folderList?.[folderList?.length - 1] || null
            this.getFiles(currentFolder?.id)
            history.push({
                pathname: '/media',
                search: currentFolder ? `?currentFolder=${currentFolder?.name}` : ''
            })
        }
        if (location?.search !== prevProps?.location?.search) {
            this.setState({
                selectedItems: []
            });
        }
    }

    async getFiles (parent = null, searchValue = '', type = null) {
        await this.setState({ fetching: true })
        await this.props.GetMedia({ parent, searchValue, type }).catch(() => {
            history.push({
                pathname: '/media',
            });
            setFolderList([])
        });
        this.setState({ fetching: false, searchValue })
    }

    deleteFile = (id) => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել ֆայլը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteFile(id);
            }
        });
    };

    deleteFolder = (id) => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել թղթապանակը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteFolder(id)
            }
        });
    };

    deleteMediaList = () => {
        const { selectedItems } = this.state;

        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել նշված ֆայլերը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                selectedItems?.length && selectedItems.forEach(item => {
                    if (item?.type === 'FOLDER') {
                        this.props.DeleteFolder(item?.id)
                    } else {
                        this.props.DeleteFile(item?.id);
                    }
                });
            }
        });
    };

    openFolder (folderData) {
        const currentFolderList = this.props.folderList || [];
        setFolderList([...currentFolderList, folderData])

        this.getFiles(folderData.id)
        history.push({
            pathname: '/media',
            search: `?currentFolder=${folderData?.name}`
        })
    }

    changeFolder = (id) => {
        const { folderList } = this.props;
        if (id === null) {
            // if ( folderList?.length ) {
            //     history.push({
            //         pathname: '/media',
            //     })
            // }
            setFolderList([])
        } else {

            const newList = [];
            folderList.some(f => {
                newList.push(f);
                return f.id === id
            });
            setFolderList(newList)


            // const newList = [];
            // folderList.some(f => {
            //     newList.push(f);
            //     return f.id === id
            // });
            // const currentFolder = newList?.[newList.length - 1]
            // setFolderList(newList)
            // this.getFiles(currentFolder?.id)
            // history.push({
            //     pathname: '/media',
            //     search: `?currentFolder=${currentFolder?.name}`
            // })
        }
    };

    onDropToFolder (folder) {
        const { draggableFile } = this.state;
        if (draggableFile && draggableFile.id !== folder.id) {
            const reqData = {
                //parentPath: folder.path || '/',
                name: draggableFile.name,
                parent: folder.id || null,
            };
            // console.log("reqData", reqData);
            this.props.UpdateFile(draggableFile.id, reqData, draggableFile.type).then(() => {
                this.props.updateMediaPath(draggableFile.id);
            })
        }
    }

    dragFile (draggableFile) {
        this.setState({
            draggableFile,
        })
    }

    openPreview (item) {
        this.setState({
            previewItem: item,
            previewModalOpen: true
        })
    }

    closePreview () {
        this.setState({
            previewItem: null,
            previewModalOpen: false
        })
    }

    toggleSearch (e) {
        e.preventDefault();
        e.stopPropagation()
        this.setState({
            searchClosed: !this.state.searchClosed,
            searchType: undefined,
            // searchValue: ''
        })
    };

    loadMoreItems () {
        const { mediaItems } = this.props;
        mediaItems.hasMore && this.props.GetMedia({
            parent: mediaItems.parent,
            searchValue: mediaItems.searchValue,
            offset: mediaItems.itemsList.length,
            reset: false
        });
    }

    getSearchValue ({ value }) {
        const { folderList } = this.props;
        const currentFolder = folderList?.[folderList?.length - 1];

        this.setState({ searchValue: value });
        if (!value) {
            this.getFiles(currentFolder?.id);
        }
    }

    search () {
        const { folderList } = this.props;
        const currentFolder = folderList?.[folderList?.length - 1];
        const { searchValue, searchType } = this.state;
        this.getFiles(currentFolder?.id, searchValue, searchType);

        this.props.getMediaHistory(searchValue);
        this.setState({
            searchClosed: !this.state.searchClosed,
            searchType: undefined,
        })
    }

    toggleSearchItem (value) {
        this.setState({
            searchValue: value,
        }, () => {
            this.search()
        })
    }

    toggleSearchType (e, type) {
        e.preventDefault();
        e.stopPropagation()
        this.setState({
            searchType: type
        })
    }

    clearSearch (e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            searchValue: ''
        }, () => this.search())
    }

    selectCard (e, media) {
        const { selectedItems } = this.state;
        if (e.ctrlKey) {
            let medias = [...selectedItems];

            if (!selectedItems?.find(item => item?.id === media?.id)) {
                medias.push({
                    id: media?.id,
                    type: media?.type
                });
            } else {
                medias = selectedItems.filter(item => item?.id !== media?.id);
            }

            this.setState({
                selectedItems: medias
            })
        }
    }

    removeSelect (e) {
        if (this.state.selectedItems.length && !e.ctrlKey) {
            this.setState({
                selectedItems: []
            });
        }
    }

    render () {
        const { mediaItems, leftSideMenuOpen, mediaView, folderList, mediaHistory } = this.props;
        const {
            fetching,
            searchValue,
            previewModalOpen,
            previewItem,
            searchClosed,
            selectedItems,
            searchType
        } = this.state;
        let itemsList = mediaItems ? mediaItems.itemsList : [];
        let currentFolder = null;
        if (folderList && folderList.length) {
            currentFolder = folderList[folderList.length - 1];
        }

        return <PageWrapper
            mediaPageTitle={<h1 className={`media-title ${leftSideMenuOpen ? 'small-title' : ''}`}>
                        <span className={'path-item'}
                              onClick={() => this.changeFolder(null)}
                              onDragOver={(e) => currentFolder && e.preventDefault()}
                              onDrop={() => currentFolder && this.onDropToFolder({})}>
                        Ֆայլեր
                    </span>
                {
                    folderList && !!folderList.length && folderList.map((folder, index) => {
                        const notLastFolderInPath = folderList.length - 1 !== index;
                        return <>
                            <span> / </span>
                            <span className={'path-item'}
                                  onClick={() => notLastFolderInPath && this.changeFolder(folder.id)}
                                  onDragOver={(e) => notLastFolderInPath && e.preventDefault()}
                                  onDrop={() => notLastFolderInPath && this.onDropToFolder(folder)}>
                                        {folder.name}
                                    </span>
                        </>
                    })
                }
            </h1>}>

            <div className={`admin-files`}>
                {fetching && <div className={'fetching-loader'}>
                    <LoadingOutlined style={{ fontSize: 54, color: "#7f1f5f" }} spin/>
                </div>}
                <div className="media-actions-header">

                    <div className={'add-buttons-wrapper'}>
                        <AddMediaFolder
                            folderList={folderList}/>
                        <MediaUploaderWrapper
                            currentFolder={currentFolder}/>
                    </div>

                    <div className="search-wrapper" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                        <div className="search-toggle-place" onClick={this.toggleSearch}>
                            <IconSearchDark title={''} className={'search-icon'}/>
                            <span>Որոնել{searchValue && `: ${searchValue}`}</span>
                            {searchValue && <IconCloseDark title={''}
                                                           className={'close-search'}
                                                           onClick={(e) => this.clearSearch(e)}/>}
                        </div>
                        {!searchClosed && <div className="search-box-wrapper">
                            <IconCloseDark title={''} onClick={this.toggleSearch} className={'close-icon'}/>

                            <div className={`search_box`}>
                                {
                                    searchType ? <Tag closable
                                                      onClose={() => this.setState({ searchType: null })}>
                                            {searchType === 'FOLDER' ? 'Թղթապանակ' : 'Ֆայլ'}
                                        </Tag>
                                        : <button type="button" className=" search_btn"
                                                  onClick={searchValue && this.search}>
                                            <IconSearchDark title={''}/>
                                        </button>
                                }
                                <InputGroup
                                    inputType={"input"}
                                    type={'text'}
                                    value={searchValue}
                                    placeholder={'Որոնել'}
                                    name={'searchValue'}
                                    autocomplete={'off'}
                                    autoFocus={true}
                                    onKeyDown={(e) => e && e.key === 'Enter' && this.search()}
                                    onChange={this.getSearchValue}/>
                            </div>

                            <div className="search-actions">
                                <button className="search-action-item"
                                        onClick={(e) => this.toggleSearchType(e, 'FOLDER')}>
                                    <IconFolder title={''}/> Թղթապանակ
                                </button>
                                <button className="search-action-item"
                                        onClick={(e) => this.toggleSearchType(e, 'FILE')}>
                                    {/*<FileIcon title={''}/>*/}
                                    <img src={iconFile} alt=""/>
                                    Ֆայլ
                                </button>
                            </div>

                            <div className="search-history">
                                {
                                    !!mediaHistory.length && mediaHistory.map((item, index) => {
                                        return <div className="history-item"
                                                    key={index}
                                                    onClick={() => this.toggleSearchItem(item)}>
                                            <IconClock title={''}/>
                                            {item}
                                            <IconCloseDark title={''}
                                                           className={'close-icon'}
                                                           onClick={(e) => {
                                                               e.preventDefault();
                                                               e.stopPropagation();
                                                               this.props.deleteMediaHistory(index)
                                                           }}/>
                                        </div>
                                    })
                                }
                            </div>
                        </div>}
                    </div>


                    <div className={"right-wrapper"}>
                        {!!selectedItems.length &&
                        <button className={'delete-btn'}>
                            <IconDeleteDark className={'delete-icon'} title={''} onClick={this.deleteMediaList}/>
                        </button>}
                        <div className={"toggle-track-view"}>
                            <LinkButton
                                className={`list-view-button ${mediaView ? 'selected' : ''}`}
                                title={<img src={iconList} alt=""/>}
                                cb={() => toggleMediaView(true)}/>
                            <LinkButton
                                className={`list-view-button ${!mediaView ? 'selected' : ''}`}
                                title={<img src={iconGrid} alt=""/>}
                                cb={() => toggleMediaView(false)}/>
                        </div>
                    </div>
                </div>

                {!fetching && <InfiniteScroll
                    hasMore={mediaItems.hasMore}
                    loadMore={this.loadMoreItems}
                    className={`media-wrapper ${mediaView ? "list-view" : ""}`}
                    pageStart={0}
                    threshold={1000}
                    useWindow={true}
                    initialLoad={false}>
                    {mediaView && <div className={"list-header"}>
                        <span>Name</span>
                        <span></span>
                        <span>Size</span>
                        <span>Type</span>
                        <span>Date added</span>
                    </div>}
                    {itemsList && !!itemsList.length && itemsList.map((item) => {
                        return item.type === "FOLDER"
                            ? <MediaFolderCard key={item.id}
                                               deleteMedia={this.deleteFolder}
                                               openFolder={this.openFolder}
                                               withEdit={true}
                                               draggable={true}
                                               dragFile={this.dragFile}
                                               onDrop={this.onDropToFolder}
                                               folderList={folderList}
                                               item={item}
                                               listView={mediaView}
                                               selectCard={this.selectCard}
                                               selectedItems={selectedItems}/>
                            :
                            <MediaFileCard key={item.id}
                                           deleteMedia={this.deleteFile}
                                           inModal={false}
                                           withEdit={true}
                                           withCopy={true}
                                           draggable={true}
                                           onDoubleClick={this.openPreview}
                                           dragFile={this.dragFile}
                                           folderList={folderList}
                                           item={item}
                                           listView={mediaView}
                                           selectCard={this.selectCard}
                                           selectedItems={selectedItems}/>
                    })}
                </InfiniteScroll>}
                <PreviewModal visible={previewModalOpen}
                              data={previewItem}
                              closeModal={this.closePreview}/>
            </div>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetMedia,
    AddFolder,
    UpdateFile,
    DeleteFile,
    DeleteFolder,
    updateMediaPath,
    getMediaHistory,
    deleteMediaHistory
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Media));
