import {BLOCK_CONSTS} from "../constants";

export const initialState = {
    blockById: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case BLOCK_CONSTS.GET_BLOCK_BY_ID:
            return {
                ...state,
                blockById: action.payload ,
            };

        default:
            return state;
    }
}
