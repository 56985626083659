import {LANGUAGES_CONSTS} from "../constants";

export const initialState = {
    languages: [],
    activeLanguages: [],
    mainLanguage: 'en'
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LANGUAGES_CONSTS.GET_LANGUAGES:
            return {
                ...state,
                languages: action.payload,
                mainLanguage: action.payload?.find(lg => lg.isMain)?.code || "en",
                activeLanguages: action.payload?.filter(lg => !lg.isHidden)
            };
        case LANGUAGES_CONSTS.DELETE_LANGUAGE:
            return {
                ...state,
                languages: state.languages.filter(item => item.id !== action.payload),
                activeLanguages: state.activeLanguages.filter(item => item.code !== action.payload),
            };
        case LANGUAGES_CONSTS.UPDATE_LANGUAGE:
            const newLanguages = state.languages.map(language =>
                language.id === action.payload.id ? action.payload : language
            );
            return {
                ...state,
                languages: newLanguages,
                activeLanguages: newLanguages?.filter(lg => !lg.isHidden)
            };
        default:
            return state;
    }
}
