//--------------------------------------- Official-server --------------------------------------------
const ADMIN_URL =  "https://app.hs.am/admin/api/v1";
const AUTH_URL =  "https://app.hs.am/auth/api/v1";
const FILES_URL =  "https://app.hs.am/file/api/v1";
export const HOST_MEDIA_URL =  "https://app.hs.am/file";

//--------------------------------------- 4Steps-server --------------------------------------------

// const ADMIN_URL =  "http://173.212.233.220:3411/api/v1";
// const AUTH_URL =  "http://173.212.233.220:3410/api/v1";
// const FILES_URL =  "http://173.212.233.220:3413/api/v1";
// export const HOST_MEDIA_URL =  "http://173.212.233.220:3413";

export const _urlAuth = AUTH_URL;
export const _urlMedia = FILES_URL + "/file";
export const _urlLanguages = ADMIN_URL + "/language";
export const _urlContact = ADMIN_URL + "/contact";
export const _urlModerator = ADMIN_URL + "/moderator";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlProduct = ADMIN_URL + "/product";
export const _urlSubscribers = ADMIN_URL + "/subscriber";
export const _urlRequests = ADMIN_URL + "/call-request";
export const _urlOrderRequests = ADMIN_URL + "/order-request";
export const _urlAbout = ADMIN_URL + "/about";
export const _urlInfo = ADMIN_URL + "/info";
export const _urlServices = ADMIN_URL + "/service";
export const _urlPartner = ADMIN_URL + "/partner";
export const _urlSliders = ADMIN_URL + "/slider";
export const _urlNews = ADMIN_URL + "/news";
export const _urlHome = ADMIN_URL + "/home";
export const _urlBlock = ADMIN_URL + "/block";

