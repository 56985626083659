// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetBlockById, UpdateBlock} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_FILE_TYPES, ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {history} from "../../configs/history";


class AddEditOption extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description'
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                pdf: undefined,
                link: '',
            },
            errors: {},
            errorsTabs: [],
            isEditing: this.props?.match?.params?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
            mediaModalType: '',
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditOption = this.addEditOption.bind(this);
    }

    async componentDidMount() {
        const {blockById} = this.props;
        const id = this.props?.match?.params?.blockId
        const optionIndex = Number(this.props?.match?.params?.id)

        const {isEditing, fieldsData} = this.state;
        this.props.GetBlockById(id)

        if (isEditing && !id) {
            history.push('/dashboard');
            return;
        }

        if (isEditing) {
            const editingData = blockById?.options?.[optionIndex];
            const translationsData = {};
            // console.log(blockById, 'blockById');
            console.log(editingData, 'editingData');
            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = editingData?.translations?.find(item => item.language === key);
                translationsData[key] = {
                    ...fieldsData.translations[key],
                    title: trItem?.title || '',
                    description: trItem?.description || '',
                };
            });
            const initStateData = {
                translations: translationsData,
                mediaMain: editingData?.mediaMain,
                link: editingData?.link,
                pdf: editingData?.pdf,
            };

            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia(type, mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set(type, mediaArray[0]?.id)
                : this.updatedDataMap.delete(type);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [type]: mediaArray[0],
            },
            errors: {
                ...errors,
                [type]: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing, mediaModalType = 'mediaMain'} = this.state;

        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set(mediaModalType, null) :
                this.updatedDataMap.delete(mediaModalType);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    toggleMediaModalType(type = '') {
        this.setState({
            mediaModalType: type
        })
    }

    async addEditOption() {
        console.log('addEditOption');
        await this.setState({
            requestLoading: true
        });
        const blockId = this.props?.match?.params?.blockId
        const optionIndex = Number(this.props?.match?.params?.id)

        const {isEditing} = this.state;
        const {translations, mediaMain, pdf, link} = this.state.fieldsData;
        const translationRequiredFields = [];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (result) {
            const trData = getTranslationData(translations);
            // console.log('trData', trData);
            let reqData = {
                translations: trData,
            }

            mediaMain?.id && (reqData.mediaMain = mediaMain?.id)
            link && (reqData.link = link)
            pdf?.id && (reqData.pdf = pdf?.id)

            const optionList = this.props.blockById.options.map(i => {
                let data = {...i}
                i?.mediaMain?.id && (data.mediaMain = i?.mediaMain?.id)
                pdf?.id && (reqData.pdf = pdf?.id)
                return data
            })

            if (isEditing) {
                let list = optionList.map((i, index) => {
                    let item = {
                        ...i,
                        pdf: i?.pdf?.id ? i?.pdf?.id : i?.pdf,
                        translations: i.translations?.map(tr => {
                            delete tr.subTitle
                            return tr
                        })
                    }

                    return index === optionIndex ? {...item, ...reqData} : item
                })
                reqData.options = list
                this.props.UpdateBlock(blockId, {
                    options: list
                }).finally(() => {
                    this.setState({requestLoading: false});
                    history.push(`/${this.props?.match?.params?.name}/block/edit/${blockId}`)
                })
            } else {

                this.props.UpdateBlock(blockId, {
                    options: [...optionList, reqData]
                }).finally(() => {
                    this.setState({requestLoading: false});
                    history.push(`/${this.props?.match?.params?.name}/block/edit/${blockId}`)
                })
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {fieldsData, errors, languageTab, mediaModalType, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};


        return <PageWrapper pageTitle={'Փոփոխել Բլոկը'}>
            <section className="slider-add-edit">
                <div className="top-side">
                    <label>Նկար<span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModalType.bind(this, 'mediaMain')}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <div className="top-side">
                    <label>PDF<span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.pdf ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModalType.bind(this, 'pdf')}
                    />
                </div>
                {fieldsData?.pdf && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.pdf}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <InputGroup inputType="input"
                            type="text"
                            label="Հղում"
                            placeholder="Հղում"
                            name="link"
                            value={fieldsData?.link}
                            initValue={this.currentData?.link}
                            error={errors?.link}
                            maxLength={100}
                            onChange={this.getInputValues}/>
                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անուն"
                                placeholder="Անուն"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Քարտի նկարագրությունը"
                                placeholder="Քարտի նկարագրությունը"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditOption}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalType}
                acceptTypes={mediaModalType === 'pdf' ? ACCEPT_FILE_TYPES : ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia.bind(this, mediaModalType)}
                closeModal={this.toggleMediaModalType.bind(this)}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    UpdateBlock,
    GetBlockById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditOption)
