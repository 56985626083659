// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";

// Import utils
import {
    changeStateField, deleteItem,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations, toggleStateField,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetServicesData,
    UpdateServicesData
} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {NestableItem} from "../../components/cards/nestableItem";
import Nestable from "react-nestable";

class Service extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description'
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                blocks: [],
            },
            errors: {},
            errorsTabs: [],
            languageTab: props.mainLanguage,
            requestLoading: false,
            isEditing: true,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
        this.updateService = this.updateService.bind(this);

    }

    async componentDidMount() {
        await this.props.GetServicesData();
        const {servicesData} = this.props;
        console.log(servicesData, 'servicesData');
        if (servicesData) {
            const {fieldsData} = this.state;
            const translationsData = {};
            if (servicesData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = servicesData?.translations.find(item => item.language === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                blocks: servicesData?.blocks
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
                errors: {},
                errorsTabs: [],
                requestLoading: false,
            });

        }
    }

    async updateService() {
        await this.setState({
            requestLoading: true
        });
        const {translations} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        if (result) {
            const reqData = {
                translations: getTranslationData(translations)
            };
            this.props.UpdateServicesData(reqData).then(() => {
                this.updatedDataMap.clear();
            }).catch(() => {
            }).finally(() => {
                this.setState({requestLoading: false});
            });
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }

    }

    getNestableItems(list) {
        const items = [];
        list && !!list.length && list.map((item) => {
            items.push({
                id: item?.id,
                title: item?.title || '-',
                withImage: true,
                path: item?.mediaMain?.path,
                link: {
                    pathname: `/services/block/edit/${item?.id}`,
                },
                data: item,
                children: []
            })
        });
        return items;
    }

    onPositionChange(items) {
        const blocks = items.map(item => item.data);

        const reqData = {
            blocks: blocks.map(block => {
                return {
                    ...block,
                    mediaMain: block?.mediaMain?.id || null,
                    icon: block?.icon?.id || null,
                }
            })
        }
        this.props.UpdateServicesData(reqData)
    }

    render() {
        const {
            fieldsData,
            errors,
            languageTab,
            requestLoading,
            errorsTabs
        } = this.state;
        const {servicesData} = this.props;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        let items = this.getNestableItems(servicesData?.blocks);
        return <PageWrapper pageTitle={'Ծառայություններ'}>

            <section className="about">
                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Գլխավոր էջի վերնագիր"
                                placeholder="Վերնագիր"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Գլխավոր էջի նկարագրությունը"
                                placeholder="նկարագրությունը"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                disabled={!this.updatedDataMap.size}
                                cb={this.updateService}/>
                </div>
                <div>
                    <label className={'list-label'}>Բաժիններ</label>

                    <Nestable
                        items={items}
                        maxDepth={1}
                        onChange={this.onPositionChange}
                        renderItem={NestableItem}
                    />

                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetServicesData,
    UpdateServicesData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Service)
