import {request, _urlBlock} from "../api";
import {BLOCK_CONSTS, HOME_CONSTS} from "../constants";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetBlockById = (id) => {
    const requestData = {
        url: `${_urlBlock}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                // console.log('data', data);
                dispatch({
                    type: BLOCK_CONSTS.GET_BLOCK_BY_ID,
                    payload: data
                });
                return data
            })
    }
};

export const UpdateBlock = (id, data) => {
    const requestData = {
        url: `${_urlBlock}/${id}    `,
        token: true,
        method: "PATCH",
        data,
    };

    return () => {
        return request(requestData)
            .then(res => {
                // dispatch({
                //     type: BLOCK_CONSTS.UPDATE_HOME,
                //     payload: res.data
                // });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                return res.data
            })
    }
};
